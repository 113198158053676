import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Rating } from '@smastrom/react-rating'
//import '@smastrom/react-rating/style.css'
import './index.css';

const AppRating: React.FC = () => {
    const navigate = useNavigate()

    const [reviewText, setReviewText] = useState('');
    const [rating, setRating] = useState(0);


    const handleSubmit = () => {
        if (reviewText && rating > 0) {
            alert('Thank you for the rating and app review.')
            navigate("/");
        }else {
            alert('Please enter the review and rating!')
        }
    }

    return (
        <div>
            <h1>Tip giver flow</h1>
            <h4>App Rating</h4>
            <input placeholder='Write a review for app' value={reviewText} onChange={(e) => setReviewText(e.target.value)} />

            <Rating
                style={{ maxWidth: 180 }}
                value={rating}
                onChange={setRating}
            />
            <button className='btn-submit' onClick={() => handleSubmit()}>Submit</button>
        </div>
    );
};

export default AppRating;
