import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import './index.css';

const Home: React.FC = () => {
  const navigate = useNavigate()

  const [tipValue, setTipValue] = useState('');

  const handleSubmit = () => {
    if (tipValue) {
      alert('Thank you for tip.')
      navigate("/tip-rating");
    }else {
      alert('Please select or enter the tip amount!')
    }
  }

  return (
    <div>
      <h1>Tip giver flow</h1>
      <h4>Tips</h4>
      <input placeholder='Leave a tips' value={tipValue} onChange={(e) => setTipValue(e.target.value)}  />
      <div className="btn-container">
        <button className='btn-box'  onClick={(e) => setTipValue('$5')}>$5</button>
        <button className='btn-box'  onClick={(e) => setTipValue('$10')}>$10</button>
        <button className='btn-box'  onClick={(e) => setTipValue('$15')}>$15</button>
      </div>
      <button className='btn-submit' onClick={() => handleSubmit()}>Submit</button>
    </div>
  );
};

export default Home;
