import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './globals/components/header/Header';
import Footer from './globals/components/footer/Footer';
import Home from './components/home/Home';
import '../src/theme/styles/Styles.css';
import TipRating from './components/tipRating/TipRating';
import AppRating from './components/appRating/AppRating';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tip-rating" element={<TipRating />} />
            <Route path="/app-rating" element={<AppRating />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
